import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Grid is a component that exposes grid system props. See the `}<a parentName="p" {...{
        "href": "https://css-tricks.com/snippets/css/complete-guide-grid/"
      }}>{`CSS Tricks Complete Guide to Grid`}</a>{` to learn more about Grid Layout.`}</p>
    <h2>{`Default example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Grid gridTemplateColumns="repeat(2, auto)" gridGap={3}>
    <Box p={3} bg="blue.2">1</Box>
    <Box p={3} bg="yellow.2">2</Box>
</Grid>
`}</code></pre>
    <h2>{`System props`}</h2>
    <p>{`Grid components get `}<inlineCode parentName="p">{`GRID`}</inlineCode>{`, `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{`, and `}<inlineCode parentName="p">{`LAYOUT`}</inlineCode>{` system props.`}</p>
    <p>{`Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <p><inlineCode parentName="p">{`Grid`}</inlineCode>{` does not get any additional props other than the system props mentioned above.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      